import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { EChartUser } from "types/chart";

const categories = [
	"2024-09-19T00:00:00.000Z",
	"2024-09-19T01:30:00.000Z",
	"2024-09-19T02:30:00.000Z",
	"2024-09-19T03:30:00.000Z",
	"2024-09-19T04:30:00.000Z",
	"2024-09-19T05:30:00.000Z",
	"2024-09-19T06:30:00.000Z",
];

interface LineChartState {
	series: {
		name: string;
		data: number[];
	}[];
}

interface ElineChart {
	data?: any;
}

const LineChartFeature: React.FC<ElineChart> = ({ data }) => {
	//////////////////////////////////////////////////////////////////
	const arrayCate: any = data?.[0];
	const dataCategories: any = [];

	if (arrayCate) {
		const items: any = arrayCate?.groupItems;
		for (const item of items) {
			dataCategories.push(item?.key);
		}
	}
	///////////////////////////////////////////////////////////////

	const [state, setState] = useState<LineChartState>({
		series: [
			{
				name: "Style Fit",
				data: [],
			},
			{
				name: "Gen Me",
				data: [],
			},
			{
				name: "Hairstyle",
				data: [],
			},
			{
				name: "Animate",
				data: [],
			},
			{
				name: "Body Shape",
				data: [],
			},
			{
				name: "Succeed generation",
				data: [],
			},
			{
				name: "Fail",
				data: [],
			},
			{
				name: "Downloaded",
				data: [],
			},
		],
	});
	const [totalY, setTotalY] = useState(arrayCate?.maxDayTotal);
	useEffect(() => {
		const styleFit: any = [];
		const genMe: any = [];
		const hairStyle: any = [];
		const animate: any = [];
		const bodyShape: any = [];
		const succeedGeneration: any = [];
		const fail: any = [];
		const downloaded: any = [];

		if (data?.length > 0) {
			for (const item of data) {
				const items: any = item?.groupItems;
				for (const e of items) {
					if (item?.title === EChartUser.STYLE_FIT) {
						styleFit.push(e?.value);
					}
					if (item?.title === EChartUser.GEN_ME) {
						genMe.push(e?.value);
					}
					if (item?.title === EChartUser.HAIR_STYLE) {
						hairStyle.push(e?.value);
					}
					if (item?.title === EChartUser.ANIMATE) {
						animate.push(e?.value);
					}
					if (item?.title === EChartUser.BODY_SHAPE) {
						bodyShape.push(e?.value);
					}
					if (item?.title === EChartUser.SUCCEED_GENERATION) {
						succeedGeneration.push(e?.value);
					}
					if (item?.title === EChartUser.FAIL) {
						fail.push(e?.value);
					}
					if (item?.title === EChartUser.DOWNLOADED) {
						fail.push(e?.value);
					}
				}

			}

			setState({
				series: [
					{
						name: "Style Fit",
						data: styleFit,
					},
					{
						name: "Gen Me",
						data: genMe,
					},
					{
						name: "Hairstyle",
						data: hairStyle,
					},
					{
						name: "Animate",
						data: animate,
					},
					{
						name: "Body Shape",
						data: bodyShape,
					},
					{
						name: "Succeed generation",
						data: succeedGeneration,
					},
					{
						name: "Fail",
						data: fail,
					},
					{
						name: "Downloaded",
						data: downloaded,
					},
				],
			});
		}
	}, [data]);

	const options: ApexOptions = {
		legend: {
			show: false,
			position: "top",
			horizontalAlign: "left",
		},
		colors: [
			"#968686",
			"#7BE2FD",
			"#FF52F0",
			"#FF9D00",
			"#5271FF",
			"#98DD0D",
			"#F45772",
			"#1737c4",
		],

		responsive: [
			{
				breakpoint: 1024,
				options: {
					chart: {
						height: 300,
					},
				},
			},
			{
				breakpoint: 1366,
				options: {
					chart: {
						height: 300,
					},
				},
			},
		],
		stroke: {
			curve: "smooth",
		},
		grid: {
			xaxis: {
				lines: {
					show: false,
				},
			},
			yaxis: {
				lines: {
					show: true,
				},
			},
		},
		dataLabels: {
			enabled: false,
		},
		markers: {
			size: 0,
			// colors: "#fff",
			strokeColors: [
				"#968686",
				"#7BE2FD",
				"#FF52F0",
				"#FF9D00",
				"#5271FF",
				"#98DD0D",
				"#F45772",
				"#1737c4",
			],
			// strokeWidth: 3,
			// strokeOpacity: 0.9,
			// strokeDashArray: 0,
			// fillOpacity: 1,
			discrete: [],
			hover: {
				size: undefined,
				sizeOffset: 5,
			},
		},
		xaxis: {
			type: "datetime",
			categories: dataCategories ?? categories,
		},
		yaxis: {
			min: 0,
			max: totalY ?? 500,
		},
		tooltip: {
			x: {
				format: "dd/MM/yy HH:mm",
			},
		},
	};

	const handleReset = () => {
		setState((prevState) => ({
			...prevState,
		}));
	};
	handleReset;

	return (
		<div className="w-[69%] h-[550px] col-span-12 rounded-[12px] border border-[#131313] bg-[#66666623] px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
			<p className="font-semibold text-[#FFFFFF] py-3">Generation Chart</p>
			<div>
				<div id="lineChart" className="-ml-5">
					<ReactApexChart
						options={options}
						series={state.series}
						type="area"
						height={350}
						colors={"#000000"}
					/>
				</div>
			</div>
			<div className="w-full mt-5 flex flex-wrap">
				<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
					<div className="flex w-full flex-wrap gap-3 sm:gap-5">
						<div className="flex min-w-47.5 justify-center items-center">
							<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#968686]" />
							<div className="w-full">
								<p className="font-semibold text-[#968686]">Style Fit</p>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
					<div className="flex w-full flex-wrap gap-3 sm:gap-5">
						<div className="flex min-w-47.5 justify-center items-center">
							<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#7BE2FD]" />
							<div className="w-full">
								<p className="font-semibold text-[#7BE2FD]">Gen Me</p>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
					<div className="flex w-full flex-wrap gap-3 sm:gap-5">
						<div className="flex min-w-47.5 justify-center items-center">
							<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#FF52F0]" />
							<div className="w-full">
								<p className="font-semibold text-[#FF52F0]">Hairstyle</p>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
					<div className="flex w-full flex-wrap gap-3 sm:gap-5">
						<div className="flex min-w-47.5 justify-center items-center">
							<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#FF9D00]" />
							<div className="w-full">
								<p className="font-semibold text-[#FF9D00]">Animate</p>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
					<div className="flex w-full flex-wrap gap-3 sm:gap-5">
						<div className="flex min-w-47.5 justify-center items-center">
							<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#5271FF]" />
							<div className="w-full">
								<p className="font-semibold text-[#5271FF]">Body Shape</p>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
					<div className="flex w-full flex-wrap gap-3 sm:gap-5">
						<div className="flex min-w-47.5 justify-center items-center">
							<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#98DD0D]" />
							<div className="w-full">
								<p className="font-semibold text-[#98DD0D]">
									Succeed generation
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
					<div className="flex w-full flex-wrap gap-3 sm:gap-5">
						<div className="flex min-w-47.5 justify-center items-center">
							<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#F45772]" />
							<div className="w-full">
								<p className="font-semibold text-[#F45772]">Fail</p>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
					<div className="flex w-full flex-wrap gap-3 sm:gap-5">
						<div className="flex min-w-47.5 justify-center items-center">
							<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#1737c4]" />
							<div className="w-full">
								<p className="font-semibold text-[#1737c4]">Downloaded</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default LineChartFeature;
