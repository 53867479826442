/** @format */

export type TParamsGetSaticUser = {
	page: number;
	limit: number;
	duration?: number;
	startDate?: string;
	endDate?: string;
	type?: string;
};

export type TParamsGetSaticPlan = {
	page: number;
	limit: number;
	duration?: number;
	startDate?: string;
	endDate?: string;
	plan: string;
	type?: string;
	status?: string;
};

export interface IUserTable {
	id: string;
	userName: string;
	email: string;
	avatar: string;
	role: string;
	credits: number;
	plan: string;
	startDate: string;
	expiredDate: string;
	totalBill: string;
	typeLogin: string;
	isAgree: string;
	isActive: boolean;
	isActiveReferral: string;
	daysRemaining: string;
	createdAt: string;
	isVerify: boolean;
	styleFit: any;
	styleHair: any;
	genMe: any;
	animationAnyone: any;
	bodyShape: any;
	checkout: string;
	cancelPlan: string;
	type: string;
	status: string;
	serviceName: string;
}

export interface IPlan {
	id: string;
	packageName: string;
	subTitle: string;
	description: string[];
	price: number;
	priceSale: number;
	sortType: number;
	unit: string;
	createdAt: string;
	updatedAt: string;
}

export interface IContactUs {
	id: string;
	firstName: string;
	lastName: string;
	company: string;
	website: string;
	email: string;
	yourRole: string;
	message: string;
	phone: string;
	describe: string;
	options: string[];
	createdAt: string;
	updatedAt: string;
}

export interface ITransactionPlan {
	id: string;
	email: string;
	payments: string;
	plan: string;
	totalBill: number;
	credit: number;
	grossAmount: number;
	paymentFee: number;
	netAmount: number;
	createdAt: string;
	updatedAt: string;
}

export interface ITransactionCredit {
	id: string;
	email: string;
	typeCharge: string;
	totalRedisUsed: number;
	totalRedisCurrent: number;
	createdAt: string;
	updatedAt: string;
}

export interface IGallery {
	id: string;
	email: any;
	name: string;
	image: string;
	video: string;
	serviceName: string;
	modelId: string;
	galleryModel: string;
	serviceId: string;
	status: string;
	imageWaterMask: string;
	categoryId: string;
	createdAt: string;
	updatedAt: string;
}

export interface IReferral {
	id: string;
	uid: string;
	email: string;
	code: string;
	type: string;
	active: string[];
	checkRemainCode: number;
	createdAt: string;
	updatedAt: string;
}

export interface IReference {
	id: string;
	email: string;
	code: string;
	type: string;
	active: any[];
	refSrc: string;
	linkShare: string;
	createdAt: string;
	updatedAt: string;
}

export interface IDashboardReference {
	name: string;
	email: string;
	referrerEmail: string;
	createdAt: string;
}

export interface INotification {
	id: string;
	title: string;
	content: string;
	type: string;
	image: string;
	hoursToAdd: string;
	isMaintain: boolean;
	createdAt: string;
	updatedAt: string;
}

export interface ISetting {
	id: string;
	uid: string;
	key: string;
	value: any;
	createdAt: string;
	updatedAt: string;
}

export interface ICategory {
	id: string;
	name: string;
	type: string;
	image: string;
	describe: string;
	createdAt: string;
	updatedAt: string;
}

export interface IClothes {
	id: string;
	name: string;
	typeOutfit: string;
	typeModel: string;
	image: string;
	merchantId: string;
	categoryId: string;
	size: string[];
	link: string;
	createdAt: string;
	updatedAt: string;
}

export interface IHairStyle {
	id: string;
	name: string;
	image: string;
	uid: string;
	imageWaterMask: string;
	createdAt: string;
	updatedAt: string;
}

export interface IGenMe {
	id: string;
	name: string;
	image: string;
	uid: string;
	imageWaterMask: string;
	createdAt: string;
	updatedAt: string;
}

export interface IModelBody {
	id: string;
	name: string;
	image: string;
	typeModel: string;
	uid: string;
	bodyShapeId: string;
	weight: number;
	height: number;
	bmi: number;
	imageWaterMask: string;
	type: string;
	imgProducts: string[];
	chestSize: number;
	hipSize: number;
	waistSize: number;
	size: any[];
	createdAt: string;
	updatedAt: string;
}

export interface IAnimationAnyone {
	id: string;
	name: string;
	video: string;
	videoShow: string;
	createdAt: string;
	updatedAt: string;
}

export interface IHistory {
	id: string;
	metaData: any;
	type: string;
	createdAt: string;
	updatedAt: string;
}

export type TParamsSatic = {
	duration?: number;
	startDate?: string;
	endDate?: string;
	plan?: string;
};

export type TParamsPlanSatic = {
	duration?: number;
	startDate?: string;
	endDate?: string;
	plan?: string;
	type?: string;
	status?: string;
};

export type TParamsSearch = {
	page: number;
	limit: number;
	search?: string;
	type?: string;
	startDate?: string;
	endDate?: string;
	duration?: number;
	plan?: string;
	referrerEmail?: string;
};

export interface IUpdateUser {
	uid: string;
	userName?: string;
	email?: string;
	avatar?: File;
	isActive?: boolean;
	isAgree?: boolean;
}

export enum ESettingKeys {
	PLANS_CREDIT = "PLANS_CREDIT",
	PLANS_UNIT = "PLANS_UNIT",
	CHARGE_SERVICE = "CHARGE_SERVICE",
	NOTIFICATION = "NOTIFICATION",
	TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS",
}

export enum ETypeOfUse {
	YAERLY = "YAERLY",
	MONTHLY = "MONTHLY",
	FREE = "FREE",
}

export enum ETypeServiceName {
	GEN_ME = "GEN_ME",
	OUTFIT = "OUTFIT",
	HAIR_SWAP = "HAIR_SWAP",
	BODY_SHAPE = "BODY_SHAPE",
	ANIMATION_ANYONE = "ANIMATION_ANYONE",
}

export enum ETypeReffral {
	PLAN_FREE = "PLAN_FREE",
}

export enum ETypeNotiOption {
	MAINTAIN = "MAINTAIN",
}

export enum ETypeCategory {
	HAIR_COLOR = "HAIR_COLOR",
}

export enum ETypeModel {
	FEMALE = "FEMALE",
	MALE = "MALE",
	OUTFIT = "OUTFIT",
	// UNISEX = "UNISEX",
}

export enum ETypeOutfit {
	UPPER = "UPPER",
	LOWER = "LOWER",
	DRESSES = "DRESSES",
}

export enum EClotheSize {
	WIDTH = "width",
	HEIGHT = "height",
	WEIGHT = "weight",
	CHEST_SIZE = "chestSize",
	WAIST_SIZE = "waistSize",
	HIP_SIZE = "hipSize",
}

export type TSizeField =
	| EClotheSize.WIDTH
	| EClotheSize.HEIGHT
	| EClotheSize.WEIGHT
	| EClotheSize.CHEST_SIZE
	| EClotheSize.WAIST_SIZE
	| EClotheSize.HIP_SIZE;

export enum EHistory {
	ADD_CREDIT = "ADD_CREDIT",
}

export enum ETypePlan {
	PRO = "PRO",
	PREMIUM = "PREMIUM",
	ENTERPRISE = "ENTERPRISE",
	FREE = "FREE",
}
