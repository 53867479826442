/** @format */

import { FC } from "react";

import { MainLayout, AuthLayout, MerchantLayout } from "components";
import {
	Analytics,
	User,
	Plan,
	Gallery,
	ContactUs,
	Referral,
	Reference,
	AddCredit,
	Notification,
	TransactionCredit,
	TransactionPlan,
	HairStyle,
	GenMe,
	ModelBody,
	PlanCredit,
	PlanUnit,
	ChargeService,
	SettingNoti,
	TermAndCondition,
	AnimationAnyone,
} from "pages/admin";
import { APP_MERCHANT, APP_ROOT, PUBLIC_URL } from "utils/routes";
import { Login } from "pages/auth/login";
import { Clothes } from "pages/merchant";
import { Feature } from "pages/admin/feature";
import { Users } from "pages/admin/user";

type TComponent = FC<{
	children: string | JSX.Element | JSX.Element[] | JSX.Element;
}>;

export interface RouterType {
	path: string;
	component: FC;
	layout: TComponent;
}

const publicRoutes: RouterType[] = [
	{
		path: PUBLIC_URL.LOGIN,
		component: Login,
		layout: AuthLayout as TComponent,
	},
];

const privateRoutes: RouterType[] = [
	{
		path: APP_ROOT.ROOT,
		component: Analytics,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.FEATURE,
		component: Feature,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.USER,
		component: Users,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.DASHBOARD.USER,
		component: User,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.DASHBOARD.PLAN,
		component: Plan,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.DASHBOARD.GALLERY,
		component: Gallery,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.DASHBOARD.CONTACT_US,
		component: ContactUs,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.DASHBOARD.REFERRAL,
		component: Referral,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.DASHBOARD.REFERENCE,
		component: Reference,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.TRANSACTION.TRANSACTION_PLAN,
		component: TransactionPlan,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.TRANSACTION.TRANSACTION_CREDIT,
		component: TransactionCredit,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.SETTINGS.PLANS_CREDIT,
		component: PlanCredit,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.SETTINGS.PLANS_UNIT,
		component: PlanUnit,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.SETTINGS.CHARGE_SERVICE,
		component: ChargeService,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.SETTINGS.NOTIFICATION,
		component: SettingNoti,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.SETTINGS.TERMS_AND_CONDITIONS,
		component: TermAndCondition,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.NOTIFICATIONS,
		component: Notification,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.GENERATION.HAIR_STYLE,
		component: HairStyle,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.GENERATION.GEM_ME,
		component: GenMe,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.GENERATION.MODEL_BODY,
		component: ModelBody,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.GENERATION.ANIMATION_ANYONE,
		component: AnimationAnyone,
		layout: MainLayout as TComponent,
	},
	{
		path: APP_ROOT.HISTORIES.ADD_CREDIT,
		component: AddCredit,
		layout: MainLayout as TComponent,
	},
];

const privateRoutesMerchant: RouterType[] = [
	// {
	// 	path: APP_MERCHANT.ROOT,
	// 	component: Category,
	// 	layout: MerchantLayout as TComponent,
	// },
	{
		path: APP_MERCHANT.ROOT,
		component: Clothes,
		layout: MerchantLayout as TComponent,
	},
];

export { privateRoutes, publicRoutes, privateRoutesMerchant };
